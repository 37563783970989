import { UserRoleEnum } from '@/shared/role.enum';
import { ENavigationKeys } from './enums/navigation-keys.enum';
import { NavigationMatcher } from './navigation-matcher';

let lang = 'en';

export const setLanguage = (newLang: string) => {
  lang = newLang;
  initializeRoutes();
};

const GlRoutes = initializeRoutes();

function initializeRoutes() {
  return new NavigationMatcher({
    basepath: `/${lang}/`,
    defaultRoles: [UserRoleEnum.ADMIN, UserRoleEnum.COACH],
    children: [
      {
        key: ENavigationKeys.Brand,
        name: 'GolfLive',
        href: 'https://golfliveapp.com',
        meta: {
          img: { src: '/main-page/simple-logo.svg', alt: 'GolfLive Logo' },
        },
      },
      {
        key: ENavigationKeys.ThankYouPage,
        name: 'Thank you page',
        href: 'thank-you-page',
      },
      {
        key: ENavigationKeys.Home,
        name: 'Home',
        href: '/',
      },
      {
        key: ENavigationKeys.Pricing,
        name: 'Pricing',
        href: '/pricing',
      },
      {
        key: ENavigationKeys.Contact,
        name: 'Contact',
        href: '/contact',
      },
      {
        key: ENavigationKeys.Dashboard,
        name: 'Dashboard',
        href: `/${lang}/portal/dashboard`,
      },
      {
        key: ENavigationKeys.Settings,
        name: 'Settings',
        href: `/${lang}/portal/settings`,
        children: [
          ENavigationKeys.SettingsGeneral,
          ENavigationKeys.SettingsPassword,
        ],
      },
      {
        key: ENavigationKeys.SettingsGeneral,
        name: 'General',
        href: `/${lang}/portal/settings/general`,
      },
      {
        key: ENavigationKeys.SettingsPassword,
        name: 'Password',
        href: `/${lang}/portal/settings/password`,
      },
      {
        key: ENavigationKeys.Billing,
        name: 'Billing',
        href: `/${lang}/portal/billing`,
        children: [
          ENavigationKeys.BillingHistory,
          ENavigationKeys.BillingSubscription,
          ENavigationKeys.PaymentMethods,
        ],
      },
      {
        key: ENavigationKeys.BillingHistory,
        name: 'History',
        href: `/${lang}/portal/billing/history`,
      },
      {
        key: ENavigationKeys.PaymentMethods,
        name: 'Payment methods',
        href: `/${lang}/portal/billing/payment-methods`,
      },
      {
        key: ENavigationKeys.BillingSubscription,
        name: 'Plans',
        href: `/${lang}/portal/billing/plans`,
      },
      {
        key: ENavigationKeys.Earnings,
        name: 'Earnings',
        href: '/earnings',
      },
      {
        key: ENavigationKeys.PrivacyPolicy,
        name: 'Privacy Policy',
        href: '/privacy-policy',
      },
      {
        key: ENavigationKeys.TermsOfService,
        name: 'Terms of service',
        href: '/terms-of-service',
      },
      {
        key: ENavigationKeys.SignIn,
        name: 'Sign-in',
        href: `/${lang}/auth/sign-in`,
      },
      {
        key: ENavigationKeys.PrisingPage,
        name: 'Pricing-page',
        href: `/${lang}/pricing-page`,
      },
      {
        key: ENavigationKeys.SignOut,
        name: 'Sign-out',
        href: `/${lang}/auth/sign-out`,
      },
      {
        key: ENavigationKeys.SignUp,
        name: 'Sign-up',
        href: `/${lang}/auth/sign-up`,
      },
      {
        key: ENavigationKeys.Connect,
        name: 'Connect',
        href: '/connect',
      },
      {
        key: ENavigationKeys.ForgotPassword,
        name: 'Forgot Password',
        href: 'forgot-password',
      },
      {
        key: ENavigationKeys.EmailSuccess,
        name: 'Email Success',
        href: `/${lang}/auth/email-success`,
      },
      {
        key: ENavigationKeys.PasswordResetSuccess,
        name: 'Reset password sent success',
        href: `/${lang}/auth/password-reset-success`,
      },
    ],
  });
}

export { GlRoutes };
